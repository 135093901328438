import React, { useState } from "react";
import { toast } from "react-toastify";

const StoryForm = ({
  childName,
  setChildName,
  interests,
  setInterests,
  lesson,
  setLesson,
  email,
  setEmail,
  voiceTone,
  setVoiceTone,
  childGender,
  setChildGender,
  format,
  setFormat,
  storyLength,
  setStoryLength,
}) => {
  const handleAddInterest = () => {
    if (customInterest.trim() !== "") {
      setInterests((prevInterests) => [
        ...prevInterests,
        { name: customInterest, selected: true },
      ]);
      setCustomInterest("");
      toast.success("Interest added successfully.");
    }
  };

  const handleInterestClick = (index) => {
    const updatedInterests = [...interests];
    updatedInterests[index].selected = !updatedInterests[index].selected;
    setInterests(updatedInterests);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddInterest();
    }
  };

  const [customInterest, setCustomInterest] = useState("");

  return (
    <>
      <div className="mb-4 relative">
        <label
          htmlFor="name"
          className="block text-gray-700 font-semibold mb-2"
        >
          Child's name
        </label>
        <div className="shadow border rounded ">
          <input
            type="text"
            id="name"
            className="w-4/5 py-2 appearance-none px-3 text-gray-700 focus:outline-none focus:shadow-outline"
            value={childName}
            placeholder="Enter child's first name here..."
            onChange={(e) => setChildName(e.target.value)}
          />
          <select
            id="gender"
            className="cursor-pointer w-1/5 relative right-2 top-0 h-full border-l border-gray-300 rounded-r px-2 focus:outline-none focus:shadow-outline"
            value={childGender}
            onChange={(e) => setChildGender(e.target.value)}
          >
            <option value="Boy">Boy</option>
            <option value="Girl">Girl</option>
          </select>
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="interests"
          className="block text-gray-700 font-semibold mb-2"
        >
          Interests (select at least one)
        </label>
        <div className="flex flex-col">
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
            {interests.map((interest, index) => (
              <div
                key={index}
                className={`rounded cursor-pointer px-4 py-2 text-sm flex items-center justify-center flex-grow bg-[#FFD8034d] ${
                  interest.selected ? "bg-[#FFD803]" : ""
                }`}
                onClick={() => handleInterestClick(index)}
              >
                {interest.name}
                <span
                  className={`ml-2 inline-block ${
                    interest.selected ? "opacity-100" : "opacity-0"
                  }`}
                >
                  ✅
                </span>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-x-2 mt-4">
            <input
              type="text"
              placeholder="Add your own interest"
              className="inline-flex items-center justify-center shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline mt-4 text-lg"
              value={customInterest}
              onChange={(e) => setCustomInterest(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              onClick={handleAddInterest}
              data-variant="slim"
              className="inline-flex cursor-pointer items-center justify-center rounded-sm border border-solid border-transparent bg-yellow-300 px-10 py-2 text-center font-semibold uppercase leading-6 text-black shadow transition-colors duration-150 ease-in-out hover:bg-black hover:text-white mt-4 text-lg"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="lesson"
          className="block text-gray-700 font-semibold mb-2"
        >
          Lesson/theme for the story (optional)
        </label>
        <input
          type="text"
          id="lesson"
          placeholder='e.g. "The importance of sharing"'
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
          value={lesson}
          onChange={(e) => setLesson(e.target.value)}
        />
      </div>
      <div className="flex flex-wrap">
        <div className=" mb-4 w-full md:w-1/2 px-2">
          <label
            htmlFor="male"
            className="block text-gray-700 font-semibold mb-2"
          >
            Voice Tone
          </label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline flex items-center justify-around">
            <div>
              <input
                type="radio"
                id="male"
                name="voiceTone"
                className="mr-2 cursor-pointer"
                value="male"
                checked={voiceTone === "male"}
                onChange={(e) => setVoiceTone(e.target.value)}
              />
              <label htmlFor="male" className="mr-4 cursor-pointer">
                Male
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="female"
                name="voiceTone"
                className="mr-2 cursor-pointer"
                value="female"
                checked={voiceTone === "female"}
                onChange={(e) => setVoiceTone(e.target.value)}
              />
              <label htmlFor="female" className="mr-4 cursor-pointer">
                Female
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4 w-full md:w-1/2 px-2">
          <label
            htmlFor="storyLength"
            className="block text-gray-700 font-semibold mb-2"
          >
            Story Length
          </label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline">
            <select
              id="storyLength"
              name="storyLength"
              className="cursor-pointer w-full focus:outline-none"
              value={storyLength}
              onChange={(e) => setStoryLength(e.target.value)}
            >
              <option value="SHORT">Short</option>
              <option value="MEDIUM">Medium</option>
              <option value="LONG">Long</option>
            </select>
          </div>
        </div>
      </div>

      {/* <div className="mb-4">
        <label
          htmlFor="male"
          className="block text-gray-700 font-semibold mb-2"
        >
          Story format
        </label>
        <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline">
          <input
            type="radio"
            id="Simple"
            name="format"
            className="mr-2"
            value="SIMPLE"
            checked={format === "SIMPLE"}
            onChange={(e) => setFormat(e.target.value)}
          />
          <label htmlFor="Simple" className="mr-4">
          Simple
          </label>
          <input
            type="radio"
            id="Novel"
            name="format"
            className="mr-2"
            value="NOVEL"
            checked={format === "NOVEL"}
            onChange={(e) => setFormat(e.target.value)}
          />
          <label htmlFor="Novel">Novel</label>
        </div>
      </div> */}
      <div className="mb-4">
        <label
          htmlFor="lesson"
          className="block text-gray-700 font-semibold mb-2"
        >
          Parent Email
        </label>
        <input
          type="email"
          id="email"
          placeholder="youremail@example.com"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </>
  );
};

export default StoryForm;
