import React from "react";

const TermsOfService = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 font-sans">
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center ">
          Terms of Service
        </h1>
        <p className="text-gray-800 mb-4">
          Welcome to Dreamy Story Land! These Terms of Service ("Terms")
          govern your use of our website, mobile applications, and any other
          products or services provided by Dreamy Story Land (collectively,
          the "Services"). By accessing or using the Services, you agree to be
          bound by these Terms.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Accounts and Registration
        </h2>
        <p className="text-gray-800 mb-4">
          To access certain features of the Services, you may need to create an
          account. You are responsible for maintaining the confidentiality of
          your account credentials and for all activities that occur under your
          account.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Content and Intellectual Property
        </h2>
        <p className="text-gray-800 mb-4">
          The Services, including all content, software, and materials, are
          owned by or licensed to Dreamy Story Land and are protected by
          intellectual property laws. You may not modify, copy, distribute,
          transmit, display, reproduce, publish, license, create derivative
          works from, transfer, or sell any content or information obtained
          from the Services without our prior written consent.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          User Conduct
        </h2>
        <p className="text-gray-800 mb-4">
          When using the Services, you agree to comply with all applicable
          laws and regulations, and to not engage in any activities that may
          be harmful, abusive, or disruptive to the Services or other users.
          You are solely responsible for any content or information you
          upload, post, or transmit through the Services.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Payments and Subscriptions
        </h2>
        <p className="text-gray-800 mb-4">
          If you purchase any products or services through the Services, you
          agree to pay all applicable fees and taxes. Dreamy Story Land may
          modify or discontinue any subscription plans or offerings at any
          time, and you will be notified of any changes to your subscription.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Termination
        </h2>
        <p className="text-gray-800 mb-4">
          Dreamy Story Land reserves the right to terminate or suspend your
          access to the Services at any time, for any reason, without prior
          notice or liability.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Disclaimer of Warranties and Limitation of Liability
        </h2>
        <p className="text-gray-800 mb-4">
          The Services are provided on an "as is" and "as available" basis,
          without warranties of any kind, either express or implied. Dreamy
          Story Land shall not be liable for any indirect, incidental,
          special, or consequential damages arising out of or in connection
          with the use of the Services.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Modifications to Terms of Service
        </h2>
        <p className="text-gray-800 mb-4">
          Dreamy Story Land reserves the right to modify or update these Terms
          of Service at any time. Any changes will be effective immediately
          upon posting the updated Terms on our website. Your continued use of
          the Services after any changes constitutes your acceptance of the
          new Terms.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Contact Us
        </h2>
        <p className="text-gray-800">
          If you have any questions or concerns about these Terms of Service,
          please contact us at contact@thinkscoopinc.com.
        </p>
      </div>
    </div>
  </div>
  );
};

export default TermsOfService;
