import React, { useState } from "react";

const StoryBox = (generatedStory) => {
  const [fullStory, setFullStory] = useState(false);
  return (
    <>
      <div className="flex justify-between mb-2">
        <h2 className="text-xl font-bold">
          {generatedStory.generatedStory.title} {generatedStory.generatedStory.emoji}
        </h2>
        <button
          className="text-blue-500 cursor-pointer"
          onClick={() => setFullStory(!fullStory)}
        >
          {!fullStory ? (
            <span>Read full story</span>
          ) : (
            <span>Hide full story</span>
          )}
        </button>
      </div>
      {!fullStory ? (
        <p className="text-lg mb-6 text-left">{generatedStory.generatedStory.description}</p>
      ) : (
        <>
          <p className="text-lg mb-6 text-left">{generatedStory.generatedStory.storyText}</p>
          <p className="text-lg mb-6 text-center">
            If You want To read Full Story Buy Full Story
          </p>
        </>
      )}
    </>
  );
};

export default StoryBox;
