import React from 'react'

const PayementButton = ({email,storyId , buttonText}) => {
    const handlePayment = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DSL_API_URL}/create-checkout-session`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                storyId: storyId,
                email: email
              })
            }
          );
      
          const session = await response.json();
          console.log("Checkout session:", session);
          window.location.href = session;
        } catch (error) {
          console.error("Error handling payment:", error);
        }
      };
  return (
    <div className=" flex justify-center max-w-lg mx-auto">
                <button
                  onClick={handlePayment}
                  className="inline-flex items-center justify-center rounded border border-transparent bg-yellow-400 px-10 py-4 text-black font-semibold uppercase leading-6 shadow-md transition duration-150 ease-in-out hover:bg-yellow-300"
                >
                  {buttonText}
                </button>
              </div>
  )
}

export default PayementButton
