import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import StoryForm from "../components/StoryForm";
import PayementButton from "../components/PayementButton";
import AudioButton from "../components/AudioButton";
import StoryBox from "../components/StoryBox";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import HomeInfo from "../components/HomeInfo";

const Homepage = () => {
  const [childName, setChildName] = useState("");
  const [interests, setInterests] = useState([
    { name: "🏰 Castles", selected: false },
    { name: "🦖 Dinosaurs", selected: false },
    { name: "💃 Dancing", selected: false },
    { name: "🦸 Superheroes", selected: false },
    { name: "🏴‍☠️ Pirates", selected: false },
    { name: "🚂 Trains", selected: false },
  ]);
  const [lesson, setLesson] = useState("");
  const [email, setEmail] = useState("");
  const [generatedStory, setGeneratedStory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [voiceTone, setVoiceTone] = useState("female");
  const [childGender, setChildGender] = useState("Boy");
  const [format, setFormat] = useState("NOVEL");
  const [storyLength, setStoryLength] = useState("SHORT");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let paymentFailure = location.state?.paymentFailure;
    if (paymentFailure) {
      console.log("Payment Failure:", paymentFailure);
      // Retrieve and parse the story, email, and childName from localStorage
      const storedStory = JSON.parse(localStorage.getItem("story"));
      const storedEmail = JSON.parse(localStorage.getItem("email"));
      const storedChildName = JSON.parse(localStorage.getItem("childName"));

      if (!storedStory) {
        navigate("/", { state: { paymentFailure: false } });
      } else {
        setGeneratedStory(storedStory);
        setEmail(storedEmail);
        setChildName(storedChildName);
      }
      toast.error("Payment was not successful. Please try again.");
    }
    // eslint-disable-next-line
  }, []);

  const handleCreateStory = () => {
    setGeneratedStory(null);
    setLoading(true);
    if (!childName || !interests.some((interest) => interest.selected)) {
      console.error("All fields are required.");
      toast.warning("All fields are required.");
      setLoading(false);
      return;
    }
    if (!email) {
      console.error("Email is required.");
      toast.warning("Email is required.");
      setLoading(false);
      return;
    }
    const storyData = {
      name: childName,
      interests: interests
        .filter((interest) => interest.selected)
        .map((interest) => interest.name),
      moral: lesson,
      email: email,
      voiceTone: voiceTone,
      childGender: childGender,
      format: format,
      storyLength: storyLength,
    };

    if (!recaptchaValue) {
      console.error("Please verify reCAPTCHA.");
      setLoading(false);
      toast.warning("Please verify reCAPTCHA.");
      return;
    }

    fetch(`${process.env.REACT_APP_DSL_API_URL}/gen-story`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...storyData, recaptchaToken: recaptchaValue }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        console.log("Generated Story:", data);
        toast.success("Story generated successfully.");
        setGeneratedStory(data);
        localStorage.setItem("story", JSON.stringify(data));
        localStorage.setItem("email", JSON.stringify(email));
        localStorage.setItem("childName", JSON.stringify(childName));
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem fetch Story:", error);
        toast.error("There was a problem fetching Story.");
        setLoading(false);
      });
  };

  const handleCreateAnotherStory = () => {
    window.location.reload();
    localStorage.removeItem("story");
    localStorage.removeItem("email");
    localStorage.removeItem("childName");
  };


  return (
    <>
      <section className="py-10 lg:py-16 p-8 mb-4 lg:max-w-screen-lg mx-auto min-h-[60vh]">
        <h1 className="font-medium text-center lg:text-6xl leading-[1.3] mt-2 mb-2 tracking-tight text-4xl">
          Spark Imagination with Screen-Free, Custom & Personalized Audio Tales
        </h1>
        <p className="lg:text-2xl text-center text-xl mt-2 mb-4 text-paragraph">
          DreamyStoryLand leverages AI to create unique, personalized audio stories for kids, offering magical, screen-free adventures with your child as the hero!
        </p>
        <div id="CreateAudioStoryForm" className="text-center p-4">
          <h1 className="font-semibold text-3xl leading-[1] mt-12 text-headline">
            Let's create your personalized audio story
          </h1>
          <h2 className="text-md mt-2">
            Tell us a little bit about your child
          </h2>
          <div className="max-w-lg mx-auto mt-8">
            <StoryForm
              childName={childName}
              setChildName={setChildName}
              interests={interests}
              setInterests={setInterests}
              lesson={lesson}
              setLesson={setLesson}
              email={email}
              setEmail={setEmail}
              voiceTone={voiceTone}
              setVoiceTone={setVoiceTone}
              childGender={childGender}
              setChildGender={setChildGender}
              format={format}
              setFormat={setFormat}
              storyLength={storyLength}
              setStoryLength={setStoryLength}
            />
            {generatedStory ? (
              <button
                onClick={handleCreateAnotherStory }
                className="mt-4 text-lg bg-blue-500 hover:bg-blue-700 text-white font-medium py-4 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Create another story
              </button>
            ) : (
              !location.state?.paymentFailure && (
                <>
                  {!loading && !generatedStory && (
                    <>
                      <div className="flex justify-center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_DSL_RECAPTCHA_KEY}
                          onChange={(value) => {
                            // console.log("Captcha value:", value);
                            setRecaptchaValue(value);
                          }}
                        />
                      </div>
                      <button
                        onClick={handleCreateStory}
                        className="mt-4 text-lg bg-blue-500 hover:bg-blue-700 text-white font-medium py-4 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Create a story{childName && ` for ${childName}`}
                      </button>
                    </>
                  )}
                </>
              )
            )}
          </div>
        </div>
        {loading && (
          <div className="text-center transition-opacity duration-500 ">
            <div>Creating a Personalised Story Just For {childName}</div>
            <div className="flex justify-center mt-2">
              <span className="sr-only">Loading...</span>
              <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
              <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
              <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
            </div>
          </div>
        )}
        <div className="mt-4">
          {generatedStory && (
            <div className="transition-opacity duration-500">
              <div className="rounded-lg p-4 shadow-md bg-blue-200 my-4 max-w-xl mx-auto">
                <StoryBox generatedStory={generatedStory} />
                {!location.state?.paymentFailure && (
                  <AudioButton
                    email={email}
                    storyId={generatedStory.storyId}
                    story={generatedStory.storyText}
                    voiceTone={voiceTone}
                  />
                )}
              </div>

              {!location.state?.paymentFailure ? (
                <PayementButton
                  buttonText={"🎁 Buy the full story with Audio for $1"}
                  storyId={generatedStory.storyId}
                  email={email}
                />
              ) : (
                <>
                  <PayementButton
                    buttonText={"Retry Payment"}
                    storyId={generatedStory.storyId}
                    email={email}
                  />
                  <button
                    onClick={() => navigate("/")}
                    className="text-lg bg-blue-500 hover:bg-blue-700 text-white font-medium py-4 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Create another story
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </section>
      <HomeInfo />
    </>
  );
};

export default Homepage;
