import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import StoryPaid from "../components/StoryPaid";
import PaymentFailure from "../assets/PaymentFailure.png";

const SuccessPage = () => {
  const [secondsLeft, setSecondsLeft] = useState(10);
  const [successNotification, setSuccessNotification] = useState(false);
  const [searchParams] = useSearchParams();
  const [storyCred, setStoryCred] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state
  const navigate = useNavigate();

  useEffect(() => {
    const session_id = searchParams.get("session_id");
    const paymentSuccess = searchParams.get("success");
    const paymentCanceled = searchParams.get("canceled");

    if (session_id) {
      if (paymentSuccess === "true") {
        setSuccessNotification(true);
        fetch(
          `${process.env.REACT_APP_DSL_API_URL}/retrieve-checkout-session?sessionId=${session_id}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Checkout Session:", data);
            setStoryCred(data);
            localStorage.removeItem("story");
            localStorage.removeItem("email");
            localStorage.removeItem("childName");
            setLoading(false); // Set loading to false when data is fetched
          })
          .catch((error) => {
            console.error("Error retrieving checkout session:", error);
            setLoading(false); // Set loading to false in case of error
          });
      }
    } else if (paymentCanceled === "true") {
      setSuccessNotification(false);
      setLoading(false); // Set loading to false if payment is canceled
    } else {
      console.log("Error Getting Params from URL");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!successNotification) {
      const interval = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => {
          if (prevSecondsLeft === 0) {
            console.log("Redirecting to dashboard");
            clearInterval(interval);
            navigate("/", { state: { paymentFailure: true } });
            return 0;
          } else {
            return prevSecondsLeft - 1;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [successNotification, navigate]);

  return (
    <div className="pb-10 pt-8 p-8 mb-4 lg:max-w-screen-lg mx-auto min-h-[90vh]">
      {loading ? ( 
      <div className=" h-[80vh] text-center transition-opacity duration-500 ">
      <div className="flex justify-center mt-2">
        <span className="">Loading...</span>
        <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
        <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
        <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
      </div>
    </div>
      ) : successNotification && storyCred ? (
        <StoryPaid email={storyCred.email} storyId={storyCred.storyId} />
      ) : (
        <>
          <div className="w-full h-[60vh] flex flex-col lg:flex-row items-center justify-center space-y-16 lg:space-y-0 space-x-8 2xl:space-x-0">
            <div className="w-full flex flex-col items-center justify-center lg:px-2 xl:px-0 text-center">
              <p className="text-4xl font-bold tracking-wider">
                Oh no, your payment failed.
              </p>
              <p className="text-xl font-bold tracking-wider mt-2">
                Don’t worry, your money is safe! If money was debited from your
                account, it will be refunded automatically in 5-7 days. <br />
                <br />
                <b>Redirecting to homepage in {secondsLeft} seconds... </b>
              </p>
            </div>
            <div className="w-1/2 lg:h-full flex lg:items-end justify-center p-4">
              <img
                src={PaymentFailure}
                alt="Payment Failed"
                className="h-auto max-h-[400px] lg:max-h-full"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SuccessPage;
