import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StoryPaid = ({ email, storyId }) => {
  const [story, setStory] = useState(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const [refetchCompleted, setRefetchCompleted] = useState(false);
  const navigate = useNavigate();

  const fetchStory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DSL_API_URL}/get-story`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ storyId, email }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch story");
      }
      const data = await response.json();
      setStory(data.story);
    } catch (error) {
      console.error("Error fetching story:", error);
    }
  };

  useEffect(() => {
    fetchStory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (refetchCount < 20 && (!story || (!story.audioLink && !story.pdfLink))) {
        console.log("Refetching...");
        fetchStory();
        setRefetchCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(intervalId);
        setRefetchCompleted(true);
      }
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [story, refetchCount]);

  const renderStoryText = () => {
    if (!story) return null;

    const lines = story.storyCreated.storyText.split('\n');

    return lines.map((line, index) => (
      <p key={index} className="text-lg mb-2 text-left">
        {line}
      </p>
    ));
  };

  return (
    <div>
      {story ? (
        <div>
          <div className="flex justify-end">
            <button
              onClick={() => navigate("/")}
              className="text-lg bg-blue-500 hover:bg-blue-700 text-white font-medium py-4 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Create another story
            </button>
          </div>
          <div className="rounded-lg p-4 shadow-md bg-[#ecf3f4] my-4 mx-auto">
            <h2 className="text-xl font-bold text-center mb-8 mt-4">
              {story.storyCreated.title} {story.storyCreated.emoji}
            </h2>
            <p className="text-lg m-4 mb-6 text-left">
            {renderStoryText()}
            </p>
            <p className="text-lg mb-6 text-center">
              Thank you for purchasing the full story. Enjoy reading!
            </p>
            {story.pdfLink && story.audioLink ? (
              <div className="flex justify-around items-center space-x-4 flex-wrap">
                <a
                  href={story.pdfLink}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-2 text-blue-500 cursor-pointer hover:text-blue-700 rounded px-4 py-2 border border-blue-500 hover:border-blue-700"
                >
                  Download PDF
                </a>
                <audio className="mt-2 " controls src={story.audioLink} />
              </div>
            ) : (
              <>
                <p className="text-lg mb-6 text-center">
                  {refetchCompleted
                    ? "Audio and PDF links are not available. Please try again after some time. If the issue persists, please contact support."
                    : "Audio and PDF links are currently being generated."}
                </p>
                <div className="text-center transition-opacity duration-500 ">
                  <div className="flex justify-center mt-2 mb-4">
                    <span className="sr-only">Loading...</span>
                    <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
                    <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
                    <div className="h-4 w-4 bg-black rounded-full animate-bounce mx-2"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </div>
  );
};

export default StoryPaid;
