import React, { useState } from "react";

const AudioButton = ({ email, storyId, story , voiceTone}) => {
  const [audioUrl, setAudioUrl] = useState("");

  const handleGenerateAudioPreview = () => {
    // const updatedStory = `${story}\n\n Story generated on Dreamy Story Land`;
  
    fetch(`${process.env.REACT_APP_DSL_API_URL}/gen-audio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        story: story ,
        email: email,
        storyId: storyId,
        voiceTone: voiceTone
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        console.log("Audio URL:", data.filePath);
        setAudioUrl(data.filePath);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  return (
    <>
      <div className="flex justify-center gap-x-4">
        <button
          className="inline-flex items-center justify-center rounded border border-transparent bg-yellow-300 shadow-md transition duration-150 ease-in-out px-10 py-4 leading-6 hover:bg-black hover:text-white font-semibold uppercase"
          onClick={handleGenerateAudioPreview}
        >
          🎧 Generate an audio preview
        </button>
      </div>
      <div className="flex justify-center mt-4">
      {audioUrl && <audio controls src={audioUrl} />}
      </div>
    </>
  );
};

export default AudioButton;
