import React from 'react'

const HomeInfo = () => {
  return (
    <>
      <section class="pb-20 p-8 bg-gray-100">
        <div class="lg:max-w-screen-lg mx-auto">
          <h2 class="text-2xl mb-6 font-semibold text-paragraph text-center">
            Why Dreamy Story Land Audio?
          </h2>
          <div class="grid lg:grid-cols-3 gap-8 grid-rows-1">
            <div class="text-center">
              <span role="img" aria-label="love" class="text-4xl mb-4">😍</span>
              <h3 class="text-xl mb-4 text-paragraph font-semibold">
                AI-Powered Personalization
              </h3>
              <p class="text-paragraph text-lg">
                Every child deserves a story where they're the star! Input your
                child's name, interests, and reading level, and let our AI craft
                a unique tale just for them.
              </p>
            </div>
            <div class="text-center">
              <span role="img" aria-label="book" class="text-4xl mb-4">📵</span>
              <h3 class="text-xl mb-4 text-paragraph font-semibold">
                Screen-Free Fun
              </h3>
              <p class="text-paragraph text-lg">
                In an age where screens dominate, offer your child the joy of
                pure auditory magic. Let their imagination paint the pictures!
              </p>
            </div>
            <div class="text-center">
              <span role="img" aria-label="heart" class="text-4xl mb-4"
                >✨</span
              >
              <h3 class="text-xl mb-4 text-paragraph font-semibold">
                Easy &amp; Affordable
              </h3>
              <p class="text-paragraph text-lg">
                Just $1, and you'll receive an audio story in your inbox within
                a couple of minutes. A small price for an experience your child will
                cherish.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="py-16 p-8 lg:max-w-screen-lg mx-auto">
        <h2 class="text-2xl mb-6 font-semibold text-paragraph text-center">
          How It Works
        </h2>
        <div class="grid lg:grid-cols-4 gap-8 grid-rows-1">
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <span role="img" aria-label="world" class="text-4xl mb-4">1️⃣</span>
            <h3 class="text-xl my-4 text-paragraph font-semibold">
              🌍 Share Your Child's World
            </h3>
            <p class="text-paragraph text-lg mb-4">
              Tell us their name, interests, and reading level.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <span role="img" aria-label="money" class="text-4xl mb-4">2️⃣</span>
            <h3 class="text-xl my-4 text-paragraph font-semibold">
              💰 Make a Wish with $2
            </h3>
            <p class="text-paragraph text-lg mb-4">
              Your child's personalized story is just a click away.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <span role="img" aria-label="magic" class="text-4xl mb-4">3️⃣</span>
            <h3 class="text-xl my-4 text-paragraph font-semibold">
              🧙 AI-Magic
            </h3>
            <p class="text-paragraph text-lg mb-4">
              Our sophisticated AI crafts a story and an engaging voice breathes
              life into it.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <span role="img" aria-label="audio" class="text-4xl mb-4">4️⃣</span>
            <h3 class="text-xl my-4 text-paragraph font-semibold">
              🔊 Listen &amp; Revel
            </h3>
            <p class="text-paragraph text-lg mb-4">
              Receive your child's audio story within 24 hours right in your
              inbox.
            </p>
          </div>
        </div>
      </section>
      <section class="py-10 p-8 lg:max-w-screen-lg mx-auto">
        <h2 class="text-2xl mb-6 font-semibold text-center">
          Frequently Asked Questions
        </h2>
        <div class="grid lg:grid-cols-2 gap-8 grid-rows-1">
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ How long is each Dreamy Story Land Audio story?
            </h3>
            <p class="text-paragraph text-lg">
              Each personalized audio story is approximately ~3 minutes long.
              This is the perfect length to keep your child engaged and excited!
              If you're interested in longer stories, please let us know at
              contact@thinkscoopinc.com
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ How quickly will I receive my child’s personalized story?
            </h3>
            <p class="text-paragraph text-lg">
              Your child's audio story will be delivered to your email within 24
              hours of placing the order. If for any reason there's a delay,
              we'll keep you updated.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ Is Dreamy Story Land safe for kids?
            </h3>
            <p class="text-paragraph text-lg">
              Yes! We prioritize safety and age-appropriateness. Our stories are
              carefully generated and reviewed to ensure they are suitable for
              children aged 12 and under.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ Can I request even more customized stories?
            </h3>
            <p class="text-paragraph text-lg">
              Absolutely! If you have specific customization requests such as
              adding additional family members or specific lessons, please feel
              free to email us at contact@thinkscoopinc.com. We'd be thrilled to
              explore how we can make your child's story even more magical.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ Is my payment secure?
            </h3>
            <p class="text-paragraph text-lg">
              Absolutely. All payment information is encrypted and secure. We
              respect your privacy and adhere to strict security standards.
            </p>
          </div>
          <div class="text-center bg-white p-6 rounded-lg shadow-lg">
            <h3 class="text-xl mb-4 text-paragraph font-semibold">
              ❓ How can I provide feedback?
            </h3>
            <p class="text-paragraph text-lg">
              Once you've received and played the audio story, we'll send a
              follow-up email to gather your thoughts. Your feedback is
              invaluable to us and helps improve the Dreamy Story Land Audio experience for
              everyone.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeInfo
