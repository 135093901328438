import React from 'react'
import DreamStory from '../assets/DreamStory.png'
import { Link } from 'react-router-dom'


const Navbar = () => {
  return (
    <>
      <nav 
      className="sticky top-0 z-40 h-auto bg-gradient-to-r from-green-400 via-yellow-400 to-pink-200 transition-all duration-150 shadow-2xl"
      >
        <div className="max-w-5xl px-6 mx-auto">
          <div className="relative flex flex-row justify-between align-center items-center py-4">
            <div className="flex items-center justify-between md:justify-normal">
              <div className="flex items-end">
                <Link className="Navbar_logo__gEyoC" aria-label="Logo" to="/">
                  <div className="flex items-center">
                  <img alt="Logo" src={DreamStory} className="logo w-12 h-12" />
                    <span className="ml-2 text-2xl font-semibold">DreamyStoryLand</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
