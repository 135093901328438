import React from 'react'
import DreamStory from '../assets/DreamStory.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer className="mx-auto max-w-full px-6 bg-zinc-900 shadow-2xl">
      <div className="grid grid-cols-1 gap-8 py-6 text-white transition-colors duration-150 border-b lg:grid-cols-12 border-zinc-600 bg-zinc-900">
        <div className="col-span-1 lg:col-span-2">
          <Link className="flex items-center flex-initial font-bold md:mr-24" to="/">
            <span className="mr-2">
              <div className="flex items-center">
              <img  src={DreamStory} alt="Logo" className="logo w-8 h-8" />
                <span className="ml-2 text-xl font-semibold">DreamyStoryLand</span>
              </div>
            </span>
          </Link>
        </div>
        <div className="col-span-1 lg:col-span-6">
          <ul className="flex flex-col flex-initial md:flex-1 md:gap-x-16 space-y-4 md:space-y-0 md:flex-row">
            <li>
              <Link className="text-white transition duration-150 ease-in-out hover:text-zinc-200" to="/">Home</Link>
            </li>
            <li>
              <Link className="text-white transition duration-150 ease-in-out hover:text-zinc-200" target="_blank" rel="noreferrer noopener" to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link className="text-white transition duration-150 ease-in-out hover:text-zinc-200" target="_blank" rel="noreferrer noopener" to="/terms-of-service">Terms of Use</Link>
            </li>
          </ul>
        </div>
        <div className="col-span-1 lg:col-span-4 md:text-right">
          <span>© 2024 DreamyStoryLand.com, Thinkscoop Technologies.</span>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer
