import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 font-sans">
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center ">
          Privacy Policy
        </h1>
        <p className="text-gray-800 mb-4">
          At Dreamy Story Land, we value your privacy and are committed to
          protecting your personal information. This Privacy Policy explains
          how we collect, use, and safeguard the information you provide to us
          on our website and in our mobile applications.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Information We Collect
        </h2>
        <p className="text-gray-800 mb-4">
          We may collect the following types of information:
        </p>
        <ul className="list-disc list-inside text-gray-800">
          <li>Personal information (e.g., name, email address, etc.)</li>
          <li>Payment information (for purchases)</li>
          <li>Usage data (e.g., pages visited, content interactions, etc.)</li>
        </ul>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Use of Information
        </h2>
        <p className="text-gray-800 mb-4">
          We use the collected information to:
        </p>
        <ul className="list-disc list-inside text-gray-800">
          <li>Provide and improve our services</li>
          <li>Process transactions and orders</li>
          <li>Communicate with you about your account or orders</li>
          <li>Send you promotional offers and updates (if you opt-in)</li>
        </ul>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Data Security
        </h2>
        <p className="text-gray-800 mb-4">
          We take reasonable measures to protect your personal information
          from unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Third-Party Services
        </h2>
        <p className="text-gray-800 mb-4">
          We may use third-party services to support our operations, such as
          payment processors or analytics providers. These third parties may
          have access to your personal information as necessary to perform
          their functions, but they are obligated not to disclose or use it for
          any other purpose.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Children's Privacy
        </h2>
        <p className="text-gray-800 mb-4">
          Our services are not directed at children under the age of 13. We do
          not knowingly collect personal information from children under 13.
          If you are a parent or guardian and believe that your child has
          provided us with personal information, please contact us to have the
          information removed.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Updates to Privacy Policy
        </h2>
        <p className="text-gray-800 mb-4">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the updated policy will be effective as
          of the "Last Updated" date shown.
        </p>

        <h2 className="text-xl font-bold mb-4 mt-8 ">
          Contact Us
        </h2>
        <p className="text-gray-800">
          If you have any questions or concerns about our Privacy Policy,
          please contact us at contact@thinkscoopinc.com.
        </p>
      </div>
    </div>
  </div>
  );
};

export default PrivacyPolicy;
